import { Box, Radio, Switch, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Button, FormLabel, Tooltip } from "react-bootstrap";
import { Button as ButtonMui } from "@mui/material";
import { useHistory, useParams } from "react-router-dom";
import { AlertDanger, AlertSuccess } from "../../Component/styles";
import api from "../../../../../services/api";
import AvatarEditor from "react-avatar-editor";
import UserProfile from "../../../../../assets/user-profile.png";

export const ColaboradorEditar = () => {
  let history = useHistory();

  const { id } = useParams();
  const [nome, setNome] = useState("");
  const [usuario, setUsuario] = useState([]);
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [linkedin, setLinkedin] = useState([]);
  const [status, setStatus] = useState("");
  const [data, setData] = useState([]);
  const [image, setImage] = useState("");
  const [statusRecord, setStatusRecord] = useState({
    type: "",
    mensagem: "",
  });
  const [notificaAtribuirChamado, setNotificaAtribuirChamado] = useState({
    value: "",
    toggle: false,
  });
  const [suporteTecnico, setSuporteTecnico] = useState({
    value: "",
    toggle: false,
  });
  const [ultimosChamados, setUltimosChamados] = useState([]);
  const [mostrarTodosChamados, setMostrarTodosChamados] = useState(false);

  const [scale, setScale] = useState(1);
  const editorRef = useRef();

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      setImage(URL.createObjectURL(file));
    }
  };

  const handleScaleChange = (e) => {
    const newScale = parseFloat(e.target.value);
    setScale(newScale);
  };

  const handleSave = () => {
    if (editorRef.current) {
      const canvas = editorRef.current.getImage();
      // You can save the canvas image or send it to your server here.
      // For this example, we'll just log the data URL.
    }
  };

  const editPerfil = async (e) => {
    /**
     * CRIADO UMA LISTA DE JSON SEPARADO
     * DEVIDO AO FATO DE QUE FOSSE PRECISO TRATAR O ENVIO DE CAMPOS ESPECIFICO
     */

    e.preventDefault();

    try {
      if (id !== "0") {
        var dadosJson = {
          id: id,
          nome: nome,
          usuario: usuario,
          email: email,
          telefone: telefone,
          //linkedin: linkedin,
          imagem: image,
          ativo: status,
        };

        await api.put(`tecnico/${id}`, dadosJson);

        setStatusRecord({
          type: "success",
          mensagem: "Registro atualizado com sucesso",
        });
      } else {
        var dadosJson = {
          nome: nome,
          usuario: usuario,
          email: email,
          telefone: telefone,
          //linkedin: linkedin,
          imagem: image,
          ativo: status,
        };

        await api.post(`tecnico/`, dadosJson);

        setStatusRecord({
          type: "success",
          mensagem: "Novo Registro criado com sucesso",
        });
      }

      const redirecionar = setTimeout(() => {
        //ENVIAR PARA PAGINA LISTA
        history.push({
          pathname: "/colaborador",
        });
      }, 2000);

      return () => clearTimeout(redirecionar);
    } catch (err) {
      console.log(err);
      setStatusRecord({
        type: "erro",
        mensagem: "Houve um erro na operação deste item",
      });
    }
  };

  useEffect(() => {
    api.get(`tecnico/${id}`).then((response) => {
      setData(response.data.records);
      setImage(response.data.records[0].imagem);
      setNome(response.data.records[0].nome);
      setUsuario(response.data.records[0].usuario);
      setEmail(response.data.records[0].email);
      setTelefone(response.data.records[0]?.telefone);
      setLinkedin(
        response.data.records[0]?.ultimosChamados[0]?.codigoTecnico?.linkedin
      );
      setStatus(response.data.records[0].ativo);
      setNotificaAtribuirChamado({
        value: response.data.records[0].notificaAtribuirChamado,
        toggle: response.data.records[0].notificaAtribuirChamado === "S",
      });
      setSuporteTecnico({
        value: response.data.records[0].suporteTecnico,
        toggle: response.data.records[0].suporteTecnico === "S",
      });
      setUltimosChamados(response.data.records[0].ultimosChamados);
    });
  }, [image]);

  const salvarSwitch = async (key, value) => {
    try {
      await api.put(`tecnico/` + id, {
        [key]: value === true ? "S" : "N",
      });
    } catch (err) {
      console.log(err);
    }
  };
  const handleChange = (stateSetter, key, value) => {
    const newValue = !value;
    stateSetter({
      value: newValue ? "S" : "N",
      toggle: newValue,
    });

    salvarSwitch(key, newValue);
  };

  const getImageAvatar = async () => {
    await api.get(`/tecnico/` + id).then((response) => {
      setImage(response.data.records[0].imagem);
    });
  };

  function uploadImagem(e) {
    var data = new FormData();
    data.append("file", e.target.files[0]);
    data.append("codigoTecnico", id);

    api
      .post(`tecnico/avatar`, data, {
        mode: "no-cors",
        method: "POST",
        withCredentials: true,
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          Accept: "application/json",
          type: "formData",
        },
        body: data,
      })
      .then(
        function (res) {
          if (res.status === 200 || res.status === 201) {
            getImageAvatar();
          } else if (res.status == 401) {
            alert("Algo Deu errado ao enviar imagem");
          }
        },
        function (err) {
          alert("Error submitting form!");
        }
      );
    /* const interval = setInterval(() => {
      getImageAvatar();
    }, 200);

    return () => clearInterval(interval); */
  }

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="tab-pane active" id="settings">
                {statusRecord.type === "erro" ? (
                  <AlertDanger>{statusRecord.mensagem}</AlertDanger>
                ) : (
                  ""
                )}
                {statusRecord.type === "success" ? (
                  <AlertSuccess>{statusRecord.mensagem}</AlertSuccess>
                ) : (
                  ""
                )}

                <section className="">
                  <form className="form-horizontal" onSubmit={editPerfil}>
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="card card-primary card-outline">
                            <div className="card-body box-profile">
                              <div className="text-center">
                                {image == null ? (
                                  <>
                                    <img
                                      style={{ width: 100, height: 100 }}
                                      alt="..."
                                      //className="avatar rounded-circle"
                                      src={UserProfile}
                                    />
                                    <label
                                      className="position-absolute "
                                      for="firstimg"
                                      style={{ zIndex: 1, top: 100, right: 90 }}
                                    >
                                      <i
                                        className="fa fa-camera"
                                        style={{
                                          color: "#ffff",
                                          backgroundColor: "#007bff",
                                          padding: 6,
                                          borderRadius: "46%",
                                        }}
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      id="firstimg"
                                      className="btn btn-primary "
                                      style={{
                                        display: "none",
                                        visibility: "none",
                                      }}
                                      onChange={(e) => uploadImagem(e)}
                                    />{" "}
                                  </>
                                ) : (
                                  <div className="position-relative">
                                    {image && (
                                      <div
                                        style={{
                                          width: "100%",
                                          display: "flex",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: "145px",
                                            height: "145px",
                                            borderRadius: "100%",
                                            outline: "2px solid #fff",
                                            overflow: "hidden",

                                            backgroundColor: "gray",
                                            zIndex: 0,
                                          }}
                                        >
                                          <AvatarEditor
                                            ref={editorRef}
                                            image={image}
                                            width={150}
                                            height={150}
                                            border={0}
                                            borderRadius={75}
                                            //backgroundColor="black"
                                            color={[255, 255, 255, 0.6]}
                                            scale={scale}
                                          />

                                          {/* <button onClick={handleSave}>Salvar</button> */}
                                        </div>
                                      </div>
                                    )}
                                    <label
                                      className="position-absolute "
                                      for="firstimg"
                                      style={{ zIndex: 1, top: 110, right: 70 }}
                                    >
                                      <i
                                        className="fa fa-camera"
                                        style={{
                                          color: "#ffff",
                                          backgroundColor: "#007bff",
                                          padding: 6,
                                          borderRadius: "46%",
                                        }}
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      id="firstimg"
                                      className="btn btn-primary "
                                      style={{
                                        display: "none",
                                        visibility: "none",
                                      }}
                                      onChange={(e) => uploadImagem(e)}
                                    />
                                  </div>
                                )}
                              </div>

                              <h3 className="profile-username text-center mt-3">
                                {nome}
                              </h3>
                              <p className="text-muted text-center">{email}</p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-12">
                              <span className="mr-5">Notificar chamado</span>
                              <span className="ml-5">
                                <Switch
                                  checked={notificaAtribuirChamado.toggle}
                                  onChange={() =>
                                    handleChange(
                                      setNotificaAtribuirChamado,
                                      "notificaAtribuirChamado",
                                      notificaAtribuirChamado.toggle
                                    )
                                  }
                                />
                              </span>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-12">
                              <span className="mr-5">Suporte técnico</span>
                              <span className="ml-5">
                                <Switch
                                  checked={suporteTecnico.toggle}
                                  onChange={() =>
                                    handleChange(
                                      setSuporteTecnico,
                                      "suporteTecnico",
                                      suporteTecnico.toggle
                                    )
                                  }
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-sm-6">
                              <label
                                htmlFor="inputEmail"
                                className="col-sm-12 col-form-label"
                              >
                                Nome
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="nome"
                                name="nome"
                                value={nome}
                                onChange={(e) => setNome(e.target.value)}
                                placeholder="Nome..."
                                required
                              />
                            </div>
                            <div className="col-sm-6">
                              <label
                                htmlFor="inputEmail"
                                className="col-sm-12 col-form-label"
                              >
                                Usuário
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="usuario"
                                name="usuario"
                                value={usuario}
                                onChange={(e) => setUsuario(e.target.value)}
                                placeholder="Usuario..."
                                required
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-6">
                              <label
                                htmlFor="inputEmail"
                                className="col-sm-12 col-form-label"
                              >
                                E-mail
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="email"
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Email..."
                                required
                              />
                            </div>
                            <div className="col-sm-6">
                              <label
                                htmlFor="inputEmail"
                                className="col-sm-12 col-form-label"
                              >
                                Telefone
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="telefone"
                                name="telefone"
                                value={telefone}
                                onChange={(e) => setTelefone(e.target.value)}
                                placeholder="Telefone..."
                                //required
                              />
                            </div>
                          </div>

                          <div className="card mt-2">
                            <div className="card-body">
                              <div className="col-md-12">
                                <div className="row">
                                  <div className="col-sm-2">
                                    <Radio
                                      checked={status == "S"}
                                      onChange={(e) =>
                                        setStatus(e.target.value)
                                      }
                                      value="S"
                                      name="radio-buttons"
                                      inputProps={{ "aria-label": "1" }}
                                    />
                                    <FormLabel id="demo-controlled-radio-buttons-group">
                                      Ativo
                                    </FormLabel>
                                  </div>
                                  <div className="col-sm-8">
                                    <Radio
                                      checked={status == "N"}
                                      onChange={(e) =>
                                        setStatus(e.target.value)
                                      }
                                      value="N"
                                      name="radio-buttons"
                                      inputProps={{ "aria-label": "0" }}
                                    />
                                    <FormLabel id="demo-controlled-radio-buttons-group">
                                      Inativo
                                    </FormLabel>
                                  </div>

                                  <div className="form-group">
                                    <div className="col-sm-2">
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                      >
                                        Salvar
                                      </button>
                                    </div>
                                  </div>
                                </div>

                                {ultimosChamados.length > 0 && (
                                  <>
                                    <Typography
                                      variant="h2"
                                      mt={4}
                                      mb={2}
                                      fontSize={18}
                                    >
                                      10 Últimas Atividades Recentes
                                    </Typography>

                                    {ultimosChamados
                                      .slice(0, mostrarTodosChamados ? 10 : 3)
                                      .map((chamado) => (
                                        <div class="card py-0 my-1">
                                          <div className="card-body py-2 d-flex justify-content-between align-items-center">
                                            <div className="d-flex w-100">
                                              <Typography
                                                mr={1}
                                                variant="body2"
                                              >
                                                {chamado.codigo}
                                              </Typography>

                                              <Tooltip title={chamado.titulo} />
                                              <Typography
                                                mr={1}
                                                variant="body2"
                                                sx={{
                                                  maxWidth: {
                                                    xl: "600px",
                                                    md: "400px",
                                                    xs: "180px",
                                                  },
                                                }}
                                                noWrap
                                              >
                                                - {chamado.titulo}
                                              </Typography>
                                            </div>
                                            <div className="d-flex align-items-center">
                                              {/* <Typography
                                                mr={1}
                                                variant="body2"
                                                className="text-muted col-3"
                                              >
                                                {chamado.classificacao.id}
                                              </Typography>
                                              <Typography
                                                mr={1}
                                                variant="body2"
                                                className="text-muted col-3"
                                              >
                                                {chamado.urgencia.id}
                                              </Typography> */}
                                              <Box
                                                sx={{
                                                  px: 2,
                                                  backgroundColor:
                                                    chamado.urgencia.cor,
                                                  borderRadius: 2,
                                                }}
                                                width={100}
                                              >
                                                <Typography
                                                  variant="body2"
                                                  color={
                                                    chamado.urgencia.cor &&
                                                    chamado.urgencia.id !== 0 &&
                                                    "#f7f5f5"
                                                  }
                                                  className={
                                                    "badge text-center w-100"
                                                  }
                                                >
                                                  <strong>
                                                    {chamado.urgencia.descricao}
                                                  </strong>
                                                </Typography>
                                              </Box>
                                            </div>
                                          </div>
                                        </div>
                                      ))}

                                    <ButtonMui
                                      variant="text"
                                      sx={{ mt: 2 }}
                                      color="primary"
                                      size="small"
                                      onClick={() =>
                                        setMostrarTodosChamados(
                                          !mostrarTodosChamados
                                        )
                                      }
                                    >
                                      Todas atividades
                                    </ButtonMui>
                                  </>
                                )}

                                {/* <div className="card">
                                  <h2 className="card-title">
                                    Atividades recentes
                                  </h2>
                                  <div className="card-body">
                                    <div className="table-responsive">
                                      <table className="table">
                                        <thead className="text-primary"></thead>

                                        <tbody>
                                          <tr>
                                            <td>Dakota Rice</td>
                                            <td>Niger</td>
                                            <td>Oud-Turnhout</td>
                                            <td className="text-right">
                                              $36,738
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Minerva Hooper</td>
                                            <td>Curaçao</td>
                                            <td>Sinaai-Waas</td>
                                            <td className="text-right">
                                              $23,789
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div> */}
                              </div>
                            </div>
                          </div>

                          {/* <form>
                          <div className="card">
                            <div className="card-body">
                              <div className="col-sm-9">
                                <div className="table-responsive">
                                  <h2 className="card-title mb-3 mt-3">
                                    Atividades recentes
                                  </h2>
                                  <table className="table">
                                    <thead className="text-primary"></thead>

                                    <tbody>
                                      <tr>
                                        <td>Dakota Rice</td>
                                        <td>Niger</td>
                                        <td>Oud-Turnhout</td>
                                        <td className="text-right">$36,738</td>
                                      </tr>
                                      <tr>
                                        <td>Minerva Hooper</td>
                                        <td>Curaçao</td>
                                        <td>Sinaai-Waas</td>
                                        <td className="text-right">$23,789</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form> */}
                        </div>
                      </div>
                    </div>
                  </form>
                </section>
              </div>
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </div>
        {/* /.col */}
      </div>
    </>
  );
};
