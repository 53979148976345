import React, { useEffect, useState } from "react";
import { getControle } from "../../../services/auth";
import api from "../../../services/api";
import dayjs from "dayjs";
import { dataAdd, formatarDataBr } from "../../../utils/utils";
import LoadingSpinner from "../../../components/controles/LoadingSpinner";
import { useQuery, useQueryClient } from "react-query";
import { Button, CircularProgress } from "@mui/material";

export default function ProcessosAlerta({ demanda }) {
  const queryClient = useQueryClient();

  const { data: dadosAlert, isLoading } = useQuery(
    ["dadosAlert", demanda],
    async () => {
      const response = await api.get(`/demanda_processos/demanda/` + demanda);
      return response.data.records;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  return (
    <>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          {dadosAlert &&
            dadosAlert
              .filter(
                (usuar) => usuar.usuario.usuario.codUsuario !== getControle()
              )
              .sort((a, b) => b.dataHoraLimite - a.dataHoraLimite)
              .map((it) => (
                <React.Fragment key={it.id}>
                  <span className="direct-chat-name float-left"></span>
                  {dataAdd(
                    it.dataHoraLimite,
                    it.tipoStatus,
                    it.usuario.usuario.nome
                  )}
                </React.Fragment>
              ))}
        </>
      )}
    </>
  );
}
